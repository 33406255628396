.share-social {
  width: 100%;
  * {
    border: unset;
    outline: unset;
  }
  &.mt-100 {
    margin-top: 100px;
  }
  &__items {
    & > button {
      margin: 4px;
    }
  }
}
