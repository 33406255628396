.breadrumb {
  padding: 20px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 540px);
  @include -xl {
    width: 100%;
  }

  .ant-breadcrumb {
    font-size: $base !important;
    padding-bottom: 8.5px;
    border-bottom: 1px solid $gray-1;
    display: inline;
    @include -xl {
      font-size: $small !important;
    }
    @include -md {
      font-size: $extra-small !important;
    }
    a,
    span {
      color: $gray;
    }
    span:last-child {
      a,
      span {
        color: $lightBlack;
      }
    }
  }
}
