.leadership {
  .tabs {
    margin-bottom: 68px;
    @include -xl {
      padding: 32px 0;
      margin-bottom: 0px;
    }
    &__item {
      @include Transition();
      padding-bottom: 14px;
      border-bottom: 8px solid transparent;
      @include -xl {
        border-bottom: 4px solid transparent;
      }
      @include -md {
        border-bottom: 2px solid transparent;
      }
      button.ant-btn {
        font-size: $heading-1;
        color: $gray-2;
        height: auto !important;
        @include -xl {
          font-size: $base;
        }
        @include -md {
          font-size: $mobile-base;
        }
      }
      &:nth-child(n + 2) {
        margin-left: 83px;
        @include -xl {
          margin-left: 20px;
        }
        @include -md {
          margin-left: 10px;
        }
      }
    }
    &__active {
      border-bottom: 8px solid $brand;
      @include -xl {
        border-bottom: 4px solid $brand;
      }
      @include -md {
        border-bottom: 2px solid $brand;
      }
      button.ant-btn {
        color: $brand;
      }
    }
  }
  .main-person {
    margin-bottom: 82px;
  }
}
