// colors
// theme specific colors
$brand: #ae0015;
$darkBlack: #140f0e;
$lightBlack: #303030;
$lighBlackOverlay: rgba(0, 0, 0, 0.65);
$orange: #ff9933;
$gray: #777777;
$gray-1: #b2b2b2;
$gray-2: #d2cac4;
$gray-3: #707070;
$white: white;
$blue: #03a9f4;
$green: #4caf50;
$red: #f44336;
// font sizes
$heading-1: 61px;
$heading-2: 31px;
$heading-3: 24px;
$base: 20px;
$mobile-base: 16px;
$small: 14px;
$extra-small: 12px;
$mb-small: 10px;

// breakpoints
$xss: 375px;
$xs: 420px;
$xs-max: 479px;
$sm-max: 575px;
$sm-min: 576px;
$md: 767px;
$lg: 991px;
$xl: 1199px;
$xxl-md: 1440px;
$xxl-max: 1679px;
$xxl-min: 1680px;
// container width
$xss-container: 320px;
$xs-container: 400px;
$sm-container: 546px;
$md-container: 730px;
$lg-container: 892px;
$xl-container: 1160px;
$xxl-container: 1650px;

:export {
  xss: $xss;
  xs: $xs;
  md: $md;
  lg: $lg;
  xl: $xl;
  xxl: $xxl-max;
  brand: $brand;
  darkBlack: $darkBlack;
  lightBlack: $lightBlack;
  orange: $orange;
  gray: $gray;
  gray-1: $gray-1;
  gray-2: $gray-2;
}
