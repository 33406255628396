.search {
  margin: 16px 0;

  &__result {
    padding: 16px 32px !important;
    @include -xl {
      padding: 8px 16px !important;
    }
    @include -md {
      padding: 4px 8px !important;
    }
  }
  &__item {
    color: $darkBlack;
    // display: block;
    font-size: $base;
    line-height: 1.5;
    font-weight: bold;
    @include -xl {
      font-size: $mobile-base;
    }
    @include -md {
      font-size: $extra-small;
    }
    &,
    &:hover {
      padding-bottom: 2px;
    }
  }
  .ant-typography {
    text-align: center;
    margin-bottom: 32px;
    @include -xl {
      font-size: $base;
      margin-bottom: 16px;
    }
    @include -md {
      font-size: $mobile-base;
      margin-bottom: 16px;
    }
  }
}
