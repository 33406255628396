.title-with-descripiton {
  padding: 80px 0;
  @include -md {
    padding: 20px 0;
  }
  margin: 0;
  margin: auto;
  text-align: center;
  @include -xl {
    width: 100%;
  }

  &__title {
    h2 {
      @include -xl {
        font-size: $heading-2 !important;
      }
      @include -md {
        font-size: $mobile-base !important;
      }
    }
  }
  &__description {
    margin-bottom: 32px;
    margin-top: 73px;
    @include -xl {
      margin-top: 36px;
      margin-bottom: 16px;
    }
    @include -md {
      margin-top: 18px;
      margin-bottom: 8px;
    }
    div.ant-typography {
      color: $lightBlack;
      @include -xl {
        font-size: $base;
      }
      @include -md {
        font-size: $extra-small;
        line-height: 18px;
      }
    }
  }
  &__button {
    // border: 1px solid red;
  }
}
