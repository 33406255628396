button.link {
  box-shadow: unset;
  border: unset;
  &,
  &:hover,
  &:focus,
  &:active {
    background: transparent;
  }
}

button.btn-more {
  padding: 0;
  height: 27px;
  font-size: $base;
  @include -md {
    font-size: $mb-small;
  }
  line-height: 100%;
  color: $orange;
  .anticon {
    font-size: 17px;
    @include -md {
      font-size: $mb-small;
    }
  }
}

.btn-with-divider {
  // border: 1px solid red;
  margin-top: 71px;
  text-align: right;
  width: 405px;
  @include -xl {
    margin-top: 24px;
  }
  @include -lg {
    margin-top: 12px;
    margin-bottom: 36px;
  }
  @include -md {
    width: 205px;
  }
  button.btn-more {
    padding-right: 122px;
    @include -md {
      padding-right: 60px;
    }
    color: $gray;
  }
  .btn-divider {
    margin-top: 9px;
    height: 7px;
    @include -xl {
      height: 5px;
    }
    @include -md {
      height: 3px;
    }
    background: $orange;
  }
}
