.about {
  img,
  td img {
    max-width: 100%;
    height: auto;
  }
  @include -md {
    tbody tr {
      td {
        width: 100% !important;
      }
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .pdf-icon.anticon {
    // fio
    font-size: 62px;
    color: $brand;
  }

  .documents {
    .document {
      width: auto;
      &__icon {
        width: 63px;
      }
      &__text {
        padding-left: 20px;
        max-width: 260px;
        font-weight: 700;
      }
      &__download {
        color: $brand;
      }
    }
  }
  .about-section {
    &__images {
      padding: 60px 0;
      img {
        @include Covered();
      }
    }
  }
}
