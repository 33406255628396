header.ant-layout-header {
  line-height: 1;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);

  @include -xl {
    box-shadow: none;
    height: auto !important;
  }
  .container {
    height: 100%;
    position: relative;
  }

  .header-top {
    height: 66%;
    &__languages {
      margin-top: 49px;
      width: 121px;
      button {
        border: unset;
        box-shadow: unset;
        padding: 0;
        color: $brand;
      }
      .anticon:last-child {
        font-size: 10px;
      }
    }

    &__logo {
      @include PerfectCenter();
      transition: all 0.5s ease;
    }

    &__search {
      margin-top: 49px;
      width: 121px;
      .ant-input-search {
        border: unset;
        border-bottom: 1px solid $gray-2;
        height: 30px;
      }
    }
  }

  .header-bottom {
    height: 34%;
    nav {
      height: 100%;
    }

    ul.ant-menu {
      display: block;
      height: 100%;
      border: unset;
      li {
        border: unset;
        &.ant-menu-item,
        &.ant-menu-submenu {
          text-transform: uppercase;
          line-height: 80px;
          height: 100%;
          margin-left: 10px;
          .anticon {
            svg {
              width: 9px;
            }
          }
          .ant-menu-submenu-title {
            padding: 0 27.5px;
            span {
              margin-right: 0px;
            }
          }
        }
      }
    }

    .menu-item-active {
      span,
      a {
        color: $brand;
      }
      &:hover {
        background: $brand;
        span,
        a {
          color: $white;
        }
      }
    }
    .menu-item-passive {
      span,
      a {
        color: $darkBlack;
      }
      &:hover {
        background: $brand;
        span,
        a {
          color: $white;
        }
      }
    }

    .ant-menu-submenu-open {
      background: $brand;
      a,
      span {
        color: $white !important;
      }
    }
  }
  // mobile header styles

  .mb-header {
    padding-top: 36px;
    padding-bottom: 24px;
    overflow-x: hidden;
    &__logo  img{
      width: 110px;
    }

    &__row {
      flex-direction: column;
    }
    &__menu-overlay {
      background: $darkBlack;
      @include FullyAbsolute();
      z-index: 100;
      position: fixed;
      transition: all 0.2s ease-in-out;
      overflow: hidden;

      &.visible {
        width: 100%;
      }
      &.hidden {
        width: 0%;
      }
    }
    &__search {
      transition: all 0.2s ease-in;
      width: 100%;
      height: 40px;
      &.visible {
        width: 100%;
      }
      &.hidden {
        width: 0%;
      }
      .ant-input-search {
        border-radius: 15px !important;
      }

      .ant-input-suffix {
        display: none;
      }
    }
    &__search-btn {
      border: none;
    }
    &__menu-actions {
      margin-top: 24px;
      margin-bottom: 66px;
      button {
        color: $white;
        border: none;
        background: transparent;
      }
    }

    &__menu-wrapper {
      max-height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
    &__menu-body {
      .ant-menu-dark .ant-menu-inline.ant-menu-sub {
        background: transparent;
      }
      ul {
        min-width: 480px;
        @include -sm {
          min-width: 320px;
        }
        li,
        li div {
          text-transform: uppercase;
          font-size: $heading-3 !important;
          @include -sm {
            font-size: $small !important;
          }
        }
      }
    }
    &__menu-languages {
      border: none;
      margin-left: 24px;
      padding: 0;
      margin-top: 10px;
      margin-bottom: 100px;
      background: transparent;
      color: $white;
      font-size: $base !important;
      @include -sm {
        font-size: $small !important;
      }
    }
  }
}

div.ant-menu-submenu {
  border-left: 3px solid $brand;

  a,
  .ant-menu-submenu-title {
    color: $darkBlack;
    &:hover {
      color: $brand;
    }
  }
}
.logo-text {
  color: $brand;
  line-height: 32px;
  margin-top: 10px;
  text-transform: uppercase;
  @include -xl {
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: center;
  }
  &.ru,
  &.en {
    font-family: Beast;
    font-size: $heading-3;
  }
  &.am {
    font-size: $small;
    font-family: Aramian;
    font-weight: bold;
  }
}
.ant-dropdown-menu {
  border: none !important;
}
