.dashboard {
  height: 100vh;
  * {
    transition: all 0.1s ease !important;
  }

  &__sider {
    background: $darkBlack !important;
    position: sticky;

    // overwite
  }
  &__content {
    padding: 0 16px;
  }

  &__languages {
    margin-top: 50px;
  }

  &__submenu_popup {
    background: $white !important;
    z-index: 100;
    ul li.ant-menu-item,
    li.ant-menu-item {
      color: $darkBlack;
      &:hover {
        background: $brand;
      }
    }
  }

  &__menu {
    li {
      text-transform: uppercase;
      span {
        font-size: $extra-small;
      }
    }
  }

  .ant-layout-sider-trigger {
    background: $brand;
  }

  textarea.ant-input {
    min-height: 120px;
    resize: none;
  }
}
