.vacancies {
  &__wrapper {
    max-width: 1200px;
    @include -xxl-md {
      max-width: 948px;
    }
    margin: auto;
  }
  &__list {
    margin: auto;
    padding-bottom: 30px;
    .ant-collapse {
      background-color: transparent !important;
    }
    div.ant-collapse-item {
      border: unset;
      div.ant-collapse-header {
        border-bottom: 1px solid $darkBlack;
      }
    }
    div.ant-collapse-item-active {
      div.ant-collapse-header {
        border-bottom: 1px solid $brand;
        &,
        span.anticon {
          color: $brand;
        }
      }
    }
  }

  &__text-key div.ant-typography {
    @include -xl {
      font-size: $small;
    }
    @include -md {
      font-size: $extra-small;
    }
    color: $gray-1;
    width: 165px;
  }
  &__text-value {
    width: calc(100% - 165px);
    div.ant-typography {
      color: $darkBlack;
      @include -xl {
        font-size: $small;
      }
      @include -md {
        font-size: $extra-small;
      }
    }
  }
  &__description {
    padding: 28px 14px 22px;
    @include -xl {
      padding: 12px 14px 10px;
    }
    @include -md {
      padding: 0px 14px;
    }
  }
  &__description-key div {
    font-weight: bold;
    color: $gray;
    @include -xl {
      font-size: $small;
    }
    @include -md {
      font-size: $extra-small;
    }
  }
  &__description-value {
    padding: 22px 14px;
    div,
    ul li {
      font-weight: normal;
      color: $gray-3;
      line-height: 27px;
      @include -xl {
        font-size: $small;
      }
      @include -md {
        font-size: $extra-small;
      }
    }
  }
  .item-resume {
    .item-resume-wrapper {
      display: none;
    }

    @include -sm {
      text-align: left;
    }

    .file-info {
      color: $gray-1;
      margin-right: 25px;
    }
    label.resume-label {
      font-size: $base;
      @include -xl {
        font-size: $small;
      }
      @include -md {
        font-size: $extra-small;
      }

      .file-button {
        cursor: pointer;
        color: $orange;
        border-bottom: 1px solid $orange;
        font-weight: bold;
      }
    }
  }
}
