footer.ant-layout-footer {
  padding-bottom: 8px !important;
  .footer {
    &__form {
      @include -md {
        margin: 32px 0;
        @include PerfectCenter();
        flex-direction: column;
      }
    }
    &__social {
      @include -md {
        @include PerfectCenter();
      }
    }
    &__subheading {
      padding-bottom: 13px;
      border: unset;
      div.ant-typography {
        font-size: $heading-2;
        color: $white;
        @include -md {
          font-size: $base;
        }
      }
    }

    &__social {
      .ant-col {
        padding-bottom: 13px;
        border-bottom: 1px solid $white;
      }
      .ant-col:nth-child(n + 2) {
        padding-left: 14px;
      }
    }

    &__privacy {
      color: $gray;
      text-align: center;
      margin-top: 44.5px;
      a {
        color: $gray;
        text-decoration: underline;
      }
    }
    &__logo {
      @include -xl {
        margin-bottom: 16px;
      }
      @include -md {
        margin-bottom: 0px;
      }

      img {
        max-width: 100%;
        @include -md {
          max-width: 110px;
        }
      }
    }

    .ant-form-item {
      margin-bottom: 0px;
    }
    .unsubscribe {
      button {
        padding: 0px;
        color: $gray;
      }
    }
  }
  .ant-form-item-control-input-content {
    padding: 0px;
    margin: 0px;
  }

  .subscribe-input.ant-input-affix-wrapper {
    background: transparent;
    width: 254px;
    border: unset;
    border-bottom: 1px solid $white;
    padding: 5px 5px 5px 0px;
    box-shadow: none;

    input.ant-input {
      &,
      &:focus,
      &:hover,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        background: transparent;
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #fff !important;
        caret-color: #fff;
      }
    }

    .ant-input-suffix {
      button.ant-btn {
        border: unset;
        background: transparent;
        color: $white;
      }
    }
  }

  .ant-form-item-explain {
    display: none;
  }
  a:active {
    color: $brand;
  }
}
