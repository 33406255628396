@font-face {
  font-family: PlayfairDisplay;
  src: url(../assets/fonts/PlayfairDisplay-Black.ttf) format('woff2');
  font-display: swap;
  font-weight: 900;
}
@font-face {
  font-family: PlayfairDisplay;
  src: url(../assets/fonts/PlayfairDisplay-Bold.ttf) format('woff2');
  font-display: swap;
  font-weight: 700;
}
@font-face {
  font-family: PlayfairDisplay;
  src: url(../assets/fonts/PlayfairDisplay-BoldItalic.ttf) format('woff2');
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Source Sans Pro;
  src: url(../assets/fonts/SourceSansPro-Regular.ttf) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Beast;
  src: url(../assets/fonts/beast-vs-spreadtall.ttf) format('woff2');
  font-display: swap;
  font-weight: normal;
}
// font for armenian
@font-face {
  font-family: Aramian;
  src: url(../assets/fonts/Aramian-Normal.ttf) format('woff2');
  // font-display: swap;
  font-weight: normal;
}
