// item is main class for  single page - person/partner
.item {
  &__avatar {
    width: 400px;
    @include -lg {
      width: 100%;
    }
    img {
      width: 100%;
    }
  }

  &__info {
    width: calc(100% - 440px);
    @include -lg {
      width: 100%;
    }
  }
  .point {
    &__text {
      &_bold {
        font-weight: bold;
      }
    }
    &__value {
      p {
        margin: 0;
      }
    }
    margin-bottom: 8px;
    .ant-typography {
      line-height: 46px;
      font-size: $heading-2;
      color: $gray;
      @include -xxl-md {
        font-size: $base;
      }
      @include -xl {
        line-height: 24px;
        font-size: $mobile-base;
      }
      @include -md {
        line-height: 20px;
        font-size: $extra-small;
      }
    }
  }
  .timeline {
    &__item * {
      line-height: 36px;
      @include -xxl-md {
        line-height: 26px;
        p {
          font-size: $base !important;
        }
      }
    }
  }
}
