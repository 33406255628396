.material-item {
  &__main-image {
    width: 300px;
    @include -xl {
      width: 100%;
    }
    img {
      width: 100%;
    }
  }
  &__main-text {
    flex: 1;
    @include -xl {
      width: 100%;
    }

    @include -xxl-md {
      h2 {
        font-size: $heading-2 !important;
      }
      h3 {
        font-size: $heading-3 !important;
      }
    }
    @include -md {
      h2 {
        font-size: $heading-3 !important;
      }
      h3 {
        font-size: $base !important;
      }
    }
  }
  &__image {
    width: 637px;
    height: auto;
    @include -xxl-md {
      width: 400px;
    }
    img {
      @include Covered();
    }
  }

  &__other {
    padding: 13px 0;
    text-align: right;
    border-bottom: 0.5px solid $gray-3;
    margin: 16px 0;
  }
  &__relative-items-horizontal {
    padding: 16px 0;
  }
}
