.single-news {
  &__title {
    h3 {
      @include -xl {
        font-size: $base !important;
        line-height: 26px !important;
      }
    }
  }
  &__other {
    div {
      color: $gray;
    }
  }
  &__rel {
    border-bottom: 1px solid $gray;
    margin: 16px 0;
    text-align: right;
    div {
      color: $gray;
    }
  }
  &__location {
    padding: 8px 0;
  }
  &__cover {
    width: 100%;
  }
  &__item {
    @include -xl {
      width: 100%;
    }
    img {
      max-width: 100%;
    }
  }
  &__relative-items-vertical {
    @include -xl {
      width: 100%;
    }
  }
  &__divider {
    position: relative;
    margin-top: 8px;
    div {
      height: 14px;
      @include -xl {
        height: 7px;
      }
      @include -md {
        height: 3px;
      }
      top: 1px;
      left: -20%;
      z-index: 10;
      position: absolute;
      width: 120%;
      background: $darkBlack;
    }
  }

  &__date div {
    color: $gray;
    @include -xl {
      font-size: $mobile-base;
    }

    @include -md {
      font-size: $small;
    }
  }
  &__content {
    padding-top: 24px;
  }
  &__share {
    padding: 40px 0 35px;
  }
  &__source {
    padding: 16px 0;
  }
}
