.login-wrapper {
  height: 40vh;
  margin-top: 120px;
  @include PerfectCenter();
  &__form.ant-form {
    background: $white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 40px;
    min-width: 400px;
    max-width: 600px;
  }
  &__button {
    display: block;
    margin: auto;
  }
}
