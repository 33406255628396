@mixin Flexed($dir, $hz, $vt, $wrap) {
  display: flex;

  @if $dir {
    flex-direction: $dir;
  } @else {
    flex-direction: row;
  }

  @if $hz {
    justify-content: $hz;
  } @else {
    justify-content: start;
  }

  @if $vt {
    align-items: $vt;
  } @else {
    align-items: start;
  }

  @if $wrap {
    flex-wrap: $wrap;
  } @else {
    flex-wrap: wrap;
  }
}

@mixin PerfectCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin FullyAbsolute() {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}
@mixin ResetMargin {
  margin: 0px 0px 0px 0px;
}

@mixin ResetPadding {
  padding: 0px 0px 0px 0px;
}
@mixin Transition {
  transition: all 0.3s ease-in-out;
}

@mixin Covered() {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  object-position: center !important;
}

@mixin BoxShadow() {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
}

@mixin ScrollBar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: $gray-2;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

// media query mixin
@mixin -xs() {
  @media (max-width: $xs) {
    @content;
  }
}

@mixin -xs-max() {
  @media (max-width: $xs-max) {
    @content;
  }
}

@mixin -sm() {
  @media (max-width: $sm-max) {
    @content;
  }
}

@mixin -md() {
  @media (max-width: $md) {
    @content;
  }
}

@mixin -lg() {
  @media (max-width: $lg) {
    @content;
  }
}

@mixin -xl() {
  @media (max-width: $xl) {
    @content;
  }
}
@mixin -xxl-md() {
  @media (max-width: $xxl-md) {
    @content;
  }
}

@mixin -xxl() {
  @media (max-width: $xxl-max) {
    @content;
  }
}

@mixin xxl-() {
  @media (min-width: $xxl-min) {
    @content;
  }
}
