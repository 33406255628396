.hashtags {
  padding: 150px 0px;
  max-width: 100%;
  @include -xl {
    padding: 75px 0px;
  }
}

.hashtag-item {
  cursor: pointer;
  padding: 9px;
  color: $gray;
  font-family: PlayfairDisplay;
  &:hover {
    @include Transition();

    &,
    &::before {
      color: $brand;
    }
  }
  &::before {
    content: '#';
    color: $gray;
  }
}
