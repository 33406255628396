.container-fluid {
  width: 100vh;
}

.container {
  margin: 0px auto;
  &.mt-6 {
    margin: 60px auto 0px;
  }
  @include xxl- {
    width: $xxl-container;
  }
  @include -xxl {
    width: calc(100% - 80px);
  }
  @include -lg {
    width: calc(100% - 40px);
  }

  @include -md {
    width: calc(100% - 32px);
  }
  @include -sm {
    width: calc(100% - 16px);
  }
}
.container-sub {
  max-width: 810px;
  @include -xl {
    padding: 0 16px;
  }
  margin: 0px auto;
  &.to-left {
    margin: 0;
  }
}
