.contact {
  &__information {
    font-family: Source Sans Pro;
    font-weight: 300;
    line-height: 28px;
    margin: auto;
    max-width: 800px;
    padding-bottom: 131px;
    & > .ant-row {
      @include -sm {
        flex-direction: column !important;
      }
    }
  }
  &__value {
    @include -sm {
      margin-left: 4.1%;
    }
  }

  &__text div {
    width: 150px !important;

    margin-left: 10px;
    color: $gray-2;
    @include -xl {
      font-size: $small;
      width: auto !important;
    }
    @include -md {
      font-size: $extra-small;
    }
  }
  &__text_black div {
    font-weight: bold;
    color: $darkBlack;
  }
  div &__heading {
    font-size: $base;
    color: $gray;
    display: inline-block;
    border-bottom: 1px solid black;
    margin-bottom: 24px !important;
    @include -xl {
      font-size: $small;
    }
    @include -md {
      font-size: $extra-small;
    }
  }
  &__form {
    input,
    textarea {
      color: $lightBlack !important;
      border-top: none !important;
      border-top: 1px solid transparent !important;
      border-left: 1px solid transparent !important;
      border-right: 1px solid transparent !important;
      box-shadow: unset !important;

      &:focus {
        border: 1px solid $lightBlack !important;
      }
    }
    .ant-form-item {
      text-align: left;
    }
    .ant-form-item-label {
      // border: 1px solid red;
      text-align: left;
      width: 240px;
      @include -xl {
        width: 180px;
      }
      @include -md {
        width: 150px;
      }

      label {
        font-size: $base;
        color: $gray;
        @include -xl {
          font-size: $small;
        }
        @include -md {
          font-size: $extra-small;
        }
      }
    }
    .ant-form-item-control {
      textarea.ant-input,
      input.ant-input {
        &,
        &:focus,
        &:hover,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          @include -xl {
            width: 100%;
          }
          @include -md {
            width: 100%;
          }
          color: $gray-1;
          font-size: $base;
          border: unset;
          border-bottom: 1px solid $darkBlack;
          @include -xl {
            font-size: $small;
          }
          @include -md {
            font-size: $extra-small;
          }
        }
      }
    }
    textarea.ant-input {
      min-height: 170px;
      max-height: 220px;
    }
    button {
      margin-top: 20px;
      height: auto;
      padding: 0;
      float: right;
      @include PerfectCenter();
      span.anticon {
        font-size: 30px;
        color: $orange;
        // border: 1px solid red;
        @include -xl {
          font-size: $small;
        }
        @include -md {
          font-size: $extra-small;
        }
      }
      span:not(.anticon) {
        color: $darkBlack;
        font-weight: 700;
        font-size: $base;
        @include -xl {
          font-size: $small;
        }
        @include -md {
          font-size: $extra-small;
        }
      }
    }
  }
  &__send {
    margin-right: 14px;
  }
}
