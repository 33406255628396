@keyframes fade {
  from {
    background: $white;
    opacity: 0.9;
  }
  to {
    background: $orange;
    opacity: 1;
  }
}
.quote {
  min-height: 1px;
  z-index: 100;
  &.visible {
    visibility: visible;
  }
  visibility: hidden;
  @include FullyAbsolute();
  position: fixed;
  min-height: 640px;
  &__section {
    background: $white;
    height: calc((100% - 402px) / 2);
    width: 100%;
  }
  &__body {
    animation: fade 0.5s ease-in-out;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
    width: 100%;
    min-height: 402px;
    background: $orange;
  }
  &__avatar {
    height: 290px;
    img {
      height: 100%;
    }
  }

  &__text {
    margin-left: 94px;
    width: 724px;
    h3.ant-typography {
      line-height: 45px !important;
      font-family: PlayfairDisplay;
      font-style: italic;
      font-weight: bold;
    }
    div.ant-typography {
      margin-top: 10px;
      font-weight: bold;
      font-family: Source Sans Pro;
    }
  }

  &__watermark {
    @include PerfectCenter();
  }
}
