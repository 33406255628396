.carousel {
  &__slide {
    height: 839px;
    @include -xl {
      height: 620px;
    }
    @include -md {
      height: 290px;
    }
    position: relative;
  }
  &__layer {
    @include FullyAbsolute();
    img {
      @include Covered();
    }
    &.layer-a {
      width: 50%;
      background: $lighBlackOverlay;
      z-index: 2;
      .wrapper {
        flex-direction: column;
        &__title {
          margin-top: 123px;
          overflow: hidden;
          width: calc(100% - 150px);
          overflow: hidden;
          margin-left: 100px;
          @include -xl {
            margin-left: 50px;
            width: calc(100% - 80px);
            margin-top: 65px;
          }
          @include -md {
            width: calc(100% - 16px);
            margin-top: 23px;
            margin-left: 16px;
          }

          h2 {
            margin: 0px !important;
            padding: 0px !important;
            color: $orange;
            @include -xxl {
              font-size: $heading-2;
            }
            @include -md {
              font-size: $base;
            }
          }
        }
        &__divider {
          border: 7px solid $orange;
          width: 93%;
          margin: 20px 0 78px 0;
          @include -md {
            border: 3px solid $orange;
            margin: 14px 0 9px 0;
          }
          overflow: hidden;
        }
        &__description {
          overflow: hidden;
          margin-left: 123px;
          width: calc(100% - 150px);
          @include -xl {
            margin-left: 50px;
            width: calc(100% - 80px);
          }
          @include -md {
            margin-left: 16px;
            width: calc(100% - 32px);
          }
          div.ant-typography {
            color: $white;
            @include -md {
              line-height: 17px;
              font-size: $extra-small;
            }
          }
        }
        &__button {
          margin: 57px 23px 0 123px;
        }
      }
    }
    &.layer-b {
      z-index: 1;
    }
  }
  &__dots {
    margin-top: 43px;
    padding-left: 123px;
    @include -md {
      margin-top: 10px;
      padding-left: 16px;
    }

    ul {
      display: inline-block;
      width: auto;

      li.ant-menu-item {
        font-family: PlayfairDisplay;
        @include Transition();
        font-size: $base;
        color: $white;
        @include -md {
          font-size: $extra-small;
        }
        &:hover {
          color: $orange;
          border-color: $orange;
        }
        &.ant-menu-item-selected {
          font-size: 32px;
          color: $orange;
          border-color: $orange;
          @include -md {
            font-size: $base;
          }
        }
      }
    }
  }
}

.book-carousel {
  height: auto;
  &__arrow {
    button.link {
      width: auto;
      height: auto;
      span {
        color: $gray-1;
        svg {
          width: 36px;
          height: 63px;
        }
      }
    }
  }
  &__title {
    .ant-typography {
      line-height: 76px !important;
      @include -xl {
        margin-top: 24px !important;
        font-size: $heading-2;
        line-height: 36px !important;
      }
      @include -md {
        line-height: 30px !important;
        font-size: $base;
      }
    }
  }
  &__slider {
    width: calc(100% - 72px);
  }
  &__slide {
    padding: 0 50px;
    @include -xl {
      padding: 0 16px;
    }
    // border: 2px solid red;
  }
  &__cover {
    img {
      @include Covered;

      @include BoxShadow();
      border: 16px solid $white;
      width: 100%;
      height: 600px !important;
      @include -xl {
        height: 400px !important;
      }
    }
  }
  &__heading {
    h3 span {
      font-family: Source Sans Pro;
      @include -xl {
        font-size: $base;
      }
      @include -md {
        font-size: $mobile-base;
      }
      &:nth-child(1) {
        color: $gray;
      }
    }
  }
  &__link {
    &:nth-child(1) {
      .anticon,
      div a {
        color: $brand;
      }
    }
    div a {
      color: $gray;
      @include -xl {
        font-size: $base;
      }
      @include -md {
        font-size: $mobile-base;
      }
    }
    .anticon {
      margin-right: 15px;
      color: $gray;
    }
  }
  &__description {
    margin-top: 10px;
    margin-bottom: 40px;
    max-height: 540px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
