.large-card {
  &.withShadow {
    @include BoxShadow();
  }
  &__cover {
    width: 100%;
    height: 400px;

    @include -xxl-md {
      height: 295px;
    }
    @include -xl {
      height: 260px;
    }
    @include -md {
      height: 240px;
    }
    img {
      @include Covered();
    }
  }
  &__date {
    padding: 12px;
    background: $orange;
    color: $white;
    left: 0;
    bottom: 0;
    position: absolute;
  }

  &__body {
    padding: 8px;
    height: 200px;
    @include -lg {
      height: auto;
    }
  }

  &__heading {
    padding: 5px 0;
    div {
      line-height: 42px;
      font-size: $heading-2;
      @include -xl {
        font-size: $base;
        line-height: 26px;
      }
      @include -md {
        font-size: $mobile-base;
        line-height: 22px;
      }
      font-weight: 700;
      font-family: PlayfairDisplay;
      &:hover {
        color: $orange;
      }
    }
  }
  &__description {
    padding: 5px 0;
    div {
      height: auto;
      color: $gray;
      @include -md {
        font-size: $extra-small;
        line-height: 18px;
      }
    }
  }
  width: 100%;
}

.small-card {
  height: 123px;

  &__cover {
    width: 100%;
    height: 100%;
    @include -xl {
      height: 123px;
    }
    img {
      @include Covered();
    }
  }

  &__heading {
    div {
      font-weight: 700;
      line-height: 1.4;
      font-family: PlayfairDisplay;
      @include -xl {
        font-size: $base;
        line-height: 26px;
      }
      @include -md {
        font-size: $mobile-base;
        line-height: 22px;
      }
      &:hover {
        color: $orange;
      }
    }
  }

  &__description {
    div {
      @include -md {
        font-size: $extra-small;
        line-height: 18px;
      }
    }
  }
  &__date {
    margin-top: 5px;
    div {
      color: $gray-1;
      @include -md {
        font-size: $extra-small;
        line-height: 18px;
      }
    }
  }
}

.vertical-card {
  &__cover {
    width: 348px;
    height: 186px;

    img {
      @include Covered();
    }
    @include -xxl-md {
      width: 250px;
    }
    @include -xl {
      width: 200px;
      height: 140px;
    }
    @include -md {
      width: 120px;
      height: 80px;
    }
  }

  &__body {
    width: calc(100% - 372px);
    @include -xxl-md {
      width: calc(100% - 274px);
    }
    @include -xl {
      width: calc(100% - 214px);
    }
    @include -md {
      width: calc(100% - 136px);
    }
  }
  &__heading {
    div {
      text-align: left;
      font-weight: 700;
      line-height: 30px;
      font-family: PlayfairDisplay;
      @include -xl {
        font-size: $base;
        line-height: 26px;
      }
      @include -md {
        font-size: $mobile-base;
        line-height: 22px;
      }
      &:hover {
        color: $orange;
      }
    }
  }

  &__description {
    @include -xxl-md {
      height: 40px;
    }
    div {
      color: $gray;
      line-height: 22px;
      @include -xxl-md {
        line-height: 20px;
        font-size: $small;
      }
    }
  }
  &__date {
    div {
      @include -xxl-md {
        font-size: $small;
      }
      color: $gray-1;
    }
  }
}

// person large card styles
.person-large-card {
  .ant-typography {
    line-height: 35px;
    @include -xxl-md {
      line-height: 28px;
    }
    @include -xl {
      line-height: 20px;
    }
  }
  &__avatar {
    width: 30%;
    height: 300px;
    img {
      @include Covered();
    }
    @include -xxl-md {
      width: 250px;
      height: 250px;
    }
    @include -xl {
      width: 200px;
      height: 200px;
    }
    @include -md {
      width: 150px;
      height: 150px;
    }
    @include -xs {
      width: 100px;
      height: 100px;
    }
  }
  &__body {
    width: calc(100% - 300px);
    padding-left: 20px;
    line-height: 35px;
    max-width: auto;
    @include -xl {
      padding-left: 10px;
      width: calc(100% - 200px);
    }
    @include -md {
      width: calc(100% - 150px);
    }
    @include -xs {
      width: calc(100% - 150px);
    }
  }
  &__fullname div.ant-typography {
    @include Transition();
    line-height: 45px;
    word-spacing: 100vw;
    font-family: PlayfairDisplay;
    font-size: $heading-2;

    @include -xxl-md {
      line-height: 35px;
      font-size: $base;
    }
    @include -xl {
      line-height: 24px;
      font-size: $mobile-base;
    }
    @include -md {
      line-height: 20px;
      font-size: $extra-small;
    }
    &:hover {
      color: $orange;
      text-decoration: underline;
    }
  }
  &__info {
    a,
    div {
      color: $gray;
      @include -xl {
        font-size: $mobile-base;
      }
      @include -md {
        font-size: $extra-small;
      }
    }
  }

  &__divider {
    &.ant-col {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }

    height: 3px;
    width: 100%;
    background: $darkBlack;
    &.orange {
      background: $orange;
    }
    &.gray {
      background: $gray;
    }
  }
  .point__value {
    line-height: 20px;
  }
}

// person small card styles
.person-small-card {
  max-width: 450px;

  .ant-typography {
    line-height: 33px;
  }
  &__avatar {
    height: 150px;
    img {
      @include Covered();
    }
  }
  &__body {
    padding-left: 20px;
  }
  &__fullname div {
    word-spacing: 100vw;
    font-family: PlayfairDisplay;
    &:hover {
      color: $orange;
      text-decoration: underline;
    }
  }
  &__role div {
    font-family: PlayfairDisplay;
    color: $gray;
  }
  &__divider {
    height: 7px;
    width: 100%;
    background: $gray;
  }
}

// partner card styles
.partner-card {
  width: 100%;
  .ant-typography {
    line-height: 28px;
    @include -sm {
      line-height: 20px;
    }
  }
  &__avatar {
    @include PerfectCenter();
    height: 220px;
    width: 220px;

    @include -xxl-md {
      width: 190px;
      height: 190px;
    }
    @include -xl {
      width: 160px;
      height: 160px;
    }
    @include -md {
      width: 120px;
      height: 120px;
    }
    img {
      @include Covered();
      display: block;
      margin: auto;
      width: 100%;
      height: 100%;
    }
  }
  &__body {
    width: calc(100% - 220px);
    padding-left: 20px;
    @include -xxl-md {
      width: calc(100% - 190px);
      height: 190px;
    }
    @include -xl {
      width: calc(100% - 190px);
      height: auto;
    }
  }

  &__title div {
    font-family: PlayfairDisplay;
    @include -xl {
      line-height: 24px;
      font-size: $mobile-base;
    }
    @include -md {
      line-height: 20px;
      font-size: $extra-small;
    }
  }
  &__divider {
    &.ant-col {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
    height: 3px;
    width: 100%;
    background: $darkBlack;
  }
  &__info div {
    color: $gray;
    @include -xl {
      font-size: $mobile-base;
    }
    @include -md {
      font-size: $extra-small;
    }
  }
}

// link card styles
.link-card {
  &__avatar {
    width: 200px;
    height: 200px;
    @include xxl-() {
      width: 150px;
      height: 150px;
    }
    @include -xxl() {
      width: 150px;
      height: 150px;
    }
    @include -xxl-md() {
      width: 110px;
      height: 110px;
    }
    img {
      @include Covered();
    }
  }

  &__body {
    padding-left: 20px;
    width: 200px;
    @include xxl-() {
      width: calc(100% - 150px);
    }
    @include -xxl() {
      width: calc(100% - 150px);
    }
    @include -xxl-md() {
      width: calc(100% - 110px);
    }
  }

  &__title div {
    line-height: 27px !important;
    line-height: 20px;
    font-family: PlayfairDisplay;
    @include -xl {
      font-size: $mobile-base;
    }
    @include -md {
      font-size: $small;
    }
    &:hover {
      color: $orange;
    }
  }
  &__info div {
    font-size: $extra-small;
    color: $gray;
    @include -xl {
      line-height: 16px;
    }
  }
  &__link {
    text-decoration: underline;
  }
}

.vertical-large-card {
  width: 100%;
  &__cover {
    width: 590px;
    height: 400px;

    img {
      @include Covered();
    }
  }
  &__body {
    padding: 46px 40px;
    width: calc(100% - 590px);
  }
  &__heading {
    &:hover {
      h3 {
        color: $orange;
      }
    }
  }
  &__date {
    padding: 18px 0;

    div {
      color: $gray;
    }
  }
  &__description div {
    color: $gray;
  }
}
