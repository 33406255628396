// single main block height
$x1: 580px;
$x1-md: 490px;

section {
  .section {
    &__main-article {
      &-fluid {
        width: 100%;
      }
      width: 800px;
      @include -xxl {
        width: calc(100% - 575px);
      }
    }

    &__articles {
      width: 750px;
      overflow-y: hidden !important;

      @include -xxl {
        width: 550px;
      }

      &.height-1x {
        max-height: $x1;
        @include -xxl-md {
          max-height: $x1-md;
        }
      }

      &.height-2x {
        max-height: (2 * $x1) + 63px;
        @include -xxl-md {
          // todo fix
          height: 1015px;
        }
      }

      // ?????????????????????
      &.height-3x {
        max-height: ($x1-md + 113px) * 3;
      }
      &.height-4x {
        max-height: ($x1-md + 117px) * 4;
      }
    }
    &__scrollbar-wrapper {
      padding-right: 16px;
    }
  }
}
.section__relative {
  @include -xxl-md {
    // padding-top: 36px;
  }
}
.scrollBar {
  background: $gray-1;
}
