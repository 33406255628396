.heading {
  @include -xl {
    margin-top: 24px;
  }
  &.mt-3 {
    margin-top: 30px;
  }
  &.mt-6 {
    margin-top: 60px;
  }
  &.mt-10 {
    margin-top: 100px;
  }
  &.mb-9 {
    margin-bottom: 90px;
    @include -xl {
      margin-bottom: 45px;
    }
    @include -md {
      margin-bottom: 25px;
    }
  }
  &.mb-3 {
    margin-bottom: 30px;
  }
  &.mt {
    margin-top: 150px;
  }
  &.mb {
    margin-bottom: 50px;
  }
  display: inline-block;

  @include xxl- {
    padding-left: 129px;
  }
  @include -xxl {
    padding-left: 40px;
  }
  @include -lg {
    padding-left: 20px;
  }
  @include -sm {
    padding-left: 8px;
  }
  @include -xl {
    padding-bottom: 10px;
  }
  @include -md {
    padding-bottom: 5px;
  }
  padding-right: 50px;
  padding-bottom: 20px;
  position: relative;
  &__text {
    line-height: 81px;
    @include -xl {
      font-size: $heading-2 !important;
      line-height: 36px !important;
    }
  }
  &__divider {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 12px;
    @include -xl {
      height: 6px;
    }
    @include -md {
      height: 3px;
    }
    background: $lightBlack;
  }
}
.subheading {
  margin-top: 48px;
  margin-bottom: 52px;
  @include -xl {
    margin-top: 16px;
    margin-bottom: 32px;
  }
  @include -md {
    margin-top: 8px;
    margin-bottom: 16px;
  }
  h3.ant-typography {
    font-weight: normal !important;
    font-family: Source Sans Pro;
    line-height: 46px;
    display: inline;
    color: $gray;
    text-transform: uppercase;
    border-bottom: 1px solid $gray;
    @include -xl {
      font-size: $base;
    }
    @include -md {
      font-size: $mobile-base;
    }
  }
}
