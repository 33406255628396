.all-events {
  &__carousel {
  }
  &__timeline {
    width: 260px;
    @include -xxl-md {
      width: 200px;
    }
    @include -xl {
      width: 150px;
    }
    @include -xs-max {
      width: 100%;
      margin-bottom: 32px;
    }
  }

  &__year {
    div {
      text-align: center;
      font-size: $heading-1;
      font-family: PlayfairDisplay;
      @include -xxl-md {
        font-size: $heading-2;
      }
    }
  }

  &__arrow {
    @include Transition();

    button {
      width: 100%;
      text-align: center;
      top: 8px;
      @include -xxl-md {
        top: 0px;
      }
      @include -xl {
        top: 5px;
      }
      bottom: 0px;
      span {
        color: $gray;
        font-size: 20px;
      }
    }
  }

  &__months.ant-menu {
    display: block;
    height: 100%;
    border: unset;
    margin-top: 34px;
    @include -xxl-md {
      margin-top: 18px;
    }
    li {
      &.ant-menu-item-selected {
        background: $orange;
        color: $white;
      }
      border: unset;
    }
  }

  &__body {
    padding: 0px 16px;
    width: calc(100% - 261px);
    @include -xxl-md {
      width: calc(100% - 200px);
    }

    @include -xl {
      width: calc(100% - 150px);
    }
    .vertical-card {
      padding-right: 0px;
    }

    @include -xs-max {
      width: 100%;
    }

    .small-card {
      .ant-typography {
        @include -xxl-md {
          line-height: 20px;
          font-size: $small;
        }
      }
    }

    .timeline-data {
      &__list {
        max-height: 750px;
      }
      &__tabs {
        .ant-tabs-bar {
          border-bottom: unset;
        }

        .ant-tabs-tab {
          padding-top: 0px;
          font-size: $heading-2;
          @include -xxl-md {
            font-size: $base;
          }
        }
      }
    }
  }
}
