.admin-table {
  &__show {
    font-size: 20px;
    color: $blue;
  }
  &__edit {
    font-size: 20px;
    color: $green;
  }
  &__remove {
    font-size: 20px;
    color: $red;
  }
  // .ant-table-header {
  //   tr th {
  //     background: $darkBlack !important;
  //     color: $white;
  //   }
  // }

  td.ant-table-cell,
  th.ant-table-cell {
    height: 65px;
    text-align: left;
    img {
      max-height: 60px;
      max-width: 60px;
    }
  }
}
