* {
  word-wrap: break-word;
}
@keyframes fadein {
  from {
    opacity: 0;
    filter: blur(2px);
  }
  to {
    opacity: 1;
    filter: blur(0px);
  }
}

.cmp {
  @include Transition();
  animation: fadein 0.5s ease-in-out;
}

html {
  margin: 0;
  padding: 0;
  font-display: swap !important;
  overflow-y: auto !important;
  overflow-x: hidden;
}
body {
  margin: 0;
  padding: 0;
  color: $lightBlack;
  font-size: $base !important;
  overflow-x: hidden;
  min-width: 320px;
  @include -xl {
    font-size: 14px !important;
  }
  @include -md {
    font-size: 12px !important;
  }
}
.main {
  margin: auto;
  max-width: 1920px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loader-static {
  user-select: none;
  img {
    width: 50px;
    height: auto;
    animation: rotate 1.5s infinite;
  }
}
.admin-loader {
  @include Flexed(null, center, center, null);
  height: 100vh;
}
.loader {
  @include FullyAbsolute();
  z-index: 1000;
  user-select: none;
  z-index: 100;
  @include Flexed(null, center, center, null);
  img {
    width: 50px;
    height: auto;
    animation: rotate 1.5s infinite;
  }
}

// h2
h2.ant-typography,
.ant-typography h2 {
  letter-spacing: 0px !important;
  font-family: PlayfairDisplay;
  // line-height: 70px !important;
  font-weight: 700 !important;
  margin: 0 !important;
  padding: 0 !important;
  @include -xxl-md {
    font-size: $heading-2;
  }
  @include -md {
    font-size: $heading-3 !important;
  }
}

// h2
h3.ant-typography,
.ant-typography h3 {
  letter-spacing: 0px !important;
  font-family: PlayfairDisplay;
  line-height: 35px !important;
  font-weight: 700 !important;
  margin: 0 !important;
  padding: 0 !important;
}
// paragraph
div.ant-typography,
.ant-typography div {
  letter-spacing: 0px !important;
  font-size: $base;
  color: $lightBlack;
  font-family: Source Sans Pro;
  line-height: 28px;
  margin: 0 !important;
  padding: 0 !important;
}

.mt-100 {
  margin-top: 100px;
}
.pt-50 {
  padding-top: 50px;
}
// empty space 10px
.em-10 {
  height: 10px;
}
.ant-pagination-jump-next,
.ant-pagination-jump-prev {
  display: none !important;
}
